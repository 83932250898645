import {
  isValidPhoneNumber,
  parsePhoneNumberWithError
} from "libphonenumber-js";
import { z } from "zod";

export const formatPhoneNumber = (number: string): string | undefined => {
  try {
    const parsed = parsePhoneNumberWithError(number, "US");

    return parsed.formatInternational().replace(/ /g, "");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return undefined;
  }
};

export const PhoneNumberSchema = z.string().refine((value) => {
  if (!value) return true;

  const parsed = formatPhoneNumber(value);

  if (!parsed) return true;

  return isValidPhoneNumber(parsed, "US");
}, "Invalid phone number");

import type { CSSProperties, FC } from "react";
import React, { useState } from "react";
import { validatePhoneNumber } from "src/lib/formatPhoneNumber";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { toast } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";

import { useNavigate } from "react-router-dom";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import translate from "src/lib/translate";
import UserCubit from "src/state/UserCubit/UserCubit";
import type { TranslationKey } from "src/types/translationKey";
import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import { z } from "zod";
import { PhoneNumberSchema } from "src/lib/zod/PhoneNumberSchema";
import ErrorBox from "../StyledComponents/ErrorBox";

const ChangeNotificationNumber: FC = () => {
  const [errorCode, setErrorCode] = useState<TranslationKey>();
  const navigate = useNavigate();

  const handleSubmit = (data: { phoneNumber: string }) => {
    setErrorCode(undefined);
    const { phoneNumber } = data;

    if (!phoneNumber) {
      return setErrorCode("invalid_phone_number");
    }

    const isValid = validatePhoneNumber(phoneNumber, "US");

    if (!isValid) {
      return setErrorCode("invalid_phone_number");
    }

    void UserCubit.setNotificationNumber(phoneNumber)
      .then(() => {
        navigate(createAppPath(Path.profile), { replace: true });
        toast.success("phoneNumberUpdated");
      })
      .catch(() => {
        setErrorCode("error_generic");
      });
  };

  return (
    <div
      style={
        {
          "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
        } as CSSProperties
      }
    >
      <Track event={TrackEvent.changeNumber} type={TrackType.start} />

      <nine-spacer s="xl"></nine-spacer>
      <nine-heading>
        <h3 className="as-h4-large">
          <Translate msg="setPhoneNumber" />
        </h3>
      </nine-heading>

      <nine-spacer s="xl"></nine-spacer>
      <Loader fixed background />

      <AutoForm
        schema={z.object({
          phoneNumber: PhoneNumberSchema
        })}
        onSubmit={handleSubmit}
        onChange={() => setErrorCode(undefined)}
      >
        <AutoFormInputField
          label={translate("phoneNumber")}
          required
          mask={{
            mask: "(000) 000-0000"
          }}
          name="phoneNumber"
        />
        {errorCode && (
          <>
            <nine-spacer s="sm"></nine-spacer>
            <ErrorBox>
              <Translate msg={errorCode} />
            </ErrorBox>
          </>
        )}
        <nine-spacer s="sm"></nine-spacer>
        <Button type="submit" theme="sunrise" style={{ margin: "auto" }}>
          <Translate msg={"confirm"} />
        </Button>
      </AutoForm>
    </div>
  );
};

export default ChangeNotificationNumber;
